import React, { FC, ReactNode } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Slide } from '@mui/material'

import { TransitionProps } from '@mui/material/transitions'

import CloseIcon from '@mui/icons-material/Close'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type CustomDialogProps = {
  title?: string
  body: ReactNode
  isOpened?: boolean
  handleClose: () => void
  'data-testid'?: string
  className?: string
}

const CustomDialog: FC<CustomDialogProps> = ({
  title,
  body,
  isOpened,
  handleClose,
  ...dialogProps
}) => {
  return (
    <Dialog
      {...dialogProps}
      aria-labelledby="instantwire-dialog-title"
      open={!!isOpened}
      onClose={handleClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth="xs"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
          backgroundColor: '#FFFFFF',
        },
      }}
    >
      {title && (
        <>
          <DialogTitle
            sx={{ m: 0, p: 1.5 }}
            id="instantwire-dialog-title"
            className="bg-[#F0F2F3] text-base"
          >
            {title}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose} className="absolute top-1 right-1">
            <CloseIcon />
          </IconButton>
        </>
      )}
      <DialogContent dividers>{body}</DialogContent>
    </Dialog>
  )
}

export default CustomDialog
