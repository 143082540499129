import { ReactElement, createContext, useCallback, useMemo, useContext, useState, useEffect } from 'react'
import { Transaction, TransactionOrder } from '@/model/transaction'
import { useRealtimeTransaction } from '@/hooks/use-realtime-transaction'
import useSessionStorage from '@/hooks/useSessionStorage'
import { submitTransaction } from '@/utils/apis/transaction'
import { useOngoingTransaction } from '@/hooks/use-ongoing-transaction'
import OngoingTransactionModal from '@/components/ongoing-transaction-modal'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'

export type TransactionContextValue = {
  transaction?: Transaction
  isTransactionInStatuses: (statuses: string[] | string) => boolean
  totalOrders: number
  onrampOrders: TransactionOrder[]
  offrampOrders: TransactionOrder[]
  saveTransaction: () => Promise<Transaction | undefined>
  currentPaymentIndex: number
  handleChangePaymentIndex: (index: number) => void
  currentOrder: TransactionOrder | undefined
  storeTransaction: (transaction: Transaction) => void
}

const initContextState: TransactionContextValue = {
  transaction: undefined,
  isTransactionInStatuses: (_statuses: string[] | string) => false,
  totalOrders: 0,
  onrampOrders: [],
  offrampOrders: [],
  saveTransaction: async () => undefined,
  currentPaymentIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangePaymentIndex: (_index: number) => { },
  currentOrder: undefined,
  storeTransaction: () => undefined,
}

export const TransactionContext = createContext<TransactionContextValue>(initContextState)

type TransactionProviderProps = HocProps & {
  transactionId?: string
}

const TransactionProvider = ({ transactionId, children }: TransactionProviderProps): ReactElement => {
  const [unsavedTransaction, setUnsavedTransaction] = useSessionStorage<Transaction | undefined>('draft_transaction', undefined)
  const { data: ongoingTransaction } = useOngoingTransaction({ enabled: !transactionId })
  const { data: transaction = unsavedTransaction } = useRealtimeTransaction(transactionId)
  const [currentPaymentIndex, setCurrentPaymentIndex] = useState<number>(0)
  const [currentOrder, setCurrentOrder] = useState<TransactionOrder>()
  const router = useRouter()
  const pathname = usePathname()
  const [
    openOngoingTransactionModal,
    setOpenOngoingTransactionModal,
  ] = useState<boolean>(!pathname?.includes('transfer-detail'))

  useEffect(() => {
    if (!transaction) return
    const order = transaction.transactionOrders?.at(currentPaymentIndex)
    setCurrentOrder(order)
  }, [transaction, currentPaymentIndex])

  const isTransactionInStatuses = useCallback((statuses: string[] | string) => {
    if (!transaction) return false

    if (typeof statuses === 'string')
      return transaction.status === statuses
    else
      return statuses.includes(transaction.status)
  }, [transaction])

  const totalOrders = useMemo(() => transaction?.transactionOrders?.length || 0, [transaction])

  const onrampOrders = useMemo(() =>
    transaction?.transactionOrders?.filter((order) => order.orderType === 'onramp') || []
    , [transaction])

  const offrampOrders = useMemo(() =>
    transaction?.transactionOrders?.filter((order) => order.orderType === 'offramp') || []
    , [transaction])

  const saveTransaction = useCallback(async () => {
    if (!unsavedTransaction) return

    const transaction = await submitTransaction({
      amount: unsavedTransaction.amount,
      currencyIn: unsavedTransaction.currencyIn,
      currencyOut: unsavedTransaction.currencyOut,
      rate: unsavedTransaction.rate,
      recipientId: unsavedTransaction.recipient?.id,
      discountAmount: unsavedTransaction.discountAmount,
      discountWirePoints: unsavedTransaction.discountWirePoints,
    })
    setUnsavedTransaction(undefined)
    return transaction
  }, [unsavedTransaction, setUnsavedTransaction])

  const handleChangePaymentIndex = useCallback((index: number) => {
    setCurrentPaymentIndex(index)
  }, [])

  const storeTransaction = useCallback((unsavedTransaction: Transaction) => {
    if (ongoingTransaction) return setOpenOngoingTransactionModal(true)

    setUnsavedTransaction(unsavedTransaction)
    router.push({ pathname: '/transfer-init' })
  }, [setUnsavedTransaction, ongoingTransaction, setOpenOngoingTransactionModal, router])

  const value = {
    transaction,
    isTransactionInStatuses,
    totalOrders,
    onrampOrders,
    offrampOrders,
    saveTransaction,
    currentPaymentIndex,
    handleChangePaymentIndex,
    currentOrder,
    storeTransaction,
  }

  return (
    <TransactionContext.Provider value={value}>
      <OngoingTransactionModal
        transaction={ongoingTransaction}
        open={openOngoingTransactionModal && ongoingTransaction}
        setOpen={setOpenOngoingTransactionModal}
      />
      {children}
    </TransactionContext.Provider>
  )
}

export default TransactionProvider

export const useCurrentTransaction = () => useContext(TransactionContext)

