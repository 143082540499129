import { Transaction } from '../model/transaction'
import { nextIsRunningCypress } from '@/utils/helpers'
import { parseTransaction } from './response-parsers.ts/transaction'
import { useAuthorizeWebsocket } from './use-authorize-websocket'
import e2eDetailTransaction from '@/root/cypress/fixtures/detail-transactions.json'

export const useRealtimeTransaction = (id?: number | string) => {
  const socketData = useAuthorizeWebsocket<Transaction>({
    channel: 'Instantwire::TransactionChannel',
    params: { id: id },
    enabled: id !== undefined,
    transformResponse: (data) => parseTransaction(data),
  })

  if (nextIsRunningCypress && id) {
    const data = e2eDetailTransaction[id.toString() as keyof typeof e2eDetailTransaction]
    // @ts-ignore
    return { data: data as Transaction }
  }

  return socketData
}
