import { authorizeRequest } from './'

type submitTransactionProps = {
  amount: number | string
  currencyIn: string
  currencyOut: string
  rate: string
  recipientId?: number
  discountAmount: number
  discountWirePoints: number
}

export const submitTransaction = async (props: submitTransactionProps) => {
  const { amount, currencyIn, currencyOut, rate,
    recipientId, discountAmount, discountWirePoints } = props

  return await authorizeRequest({
    path: 'instantwire/transactions',
    method: 'POST',
    params: {
      amount: amount,
      currency_in: currencyIn,
      currency_out: currencyOut,
      rate,
      instantwire_recipient_id: recipientId,
      discount_amount: discountAmount,
      discount_wire_points: discountWirePoints,
    },
  })
}

type updateTransactionProps = {
  id: number | string
  recipientId: number | string
}

export const updateTransaction = async (props: updateTransactionProps) => {
  const { id, recipientId } = props

  await authorizeRequest({
    path: `instantwire/transactions/${id}`,
    method: 'PATCH',
    params: {
      instantwire_recipient_id: recipientId,
    },
  })
}

export const cancelTransaction = async ({ id }: { id: number | string }) => {

  await authorizeRequest({
    path: `instantwire/transactions/${id}/cancel`,
    method: 'PATCH',
  })
}

export const completeTransaction = async ({ id }: { id: number | string }) => {
  await authorizeRequest({
    path: `instantwire/transactions/${id}/complete`,
    method: 'PATCH',
  })
}

type addOfframpDisputeReasonProps = {
  id: number | string
  reason: string
}

export const addOfframpDisputeReason = async (props: addOfframpDisputeReasonProps) => {
  const { id, reason } = props

  await authorizeRequest({
    path: `instantwire/transactions/${id}`,
    method: 'PATCH',
    params: {
      extra_data: { dispute_reason: reason },
    },
  })
}
